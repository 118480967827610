import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { userlogged } from 'src/app/models/authentication ';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar-header',
  templateUrl: './nav-bar-header.component.html',
  styleUrls: ['./nav-bar-header.component.scss']
})
export class NavBarHeaderComponent implements OnInit {
  user: userlogged;

  constructor(private authService: AuthService, private router:Router) {
    this.user = authService.getUserLogged();
  }

  ngOnInit() {}


  logout() {
    this.authService.logOut().then(data => {
      this.router.navigateByUrl('/login');
    });
  }
}
