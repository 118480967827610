import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loading = false;

  username: string = '';
  password: string='';
  error: string;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('username') != null) {
      this.router.navigate(['/report']);
    }
  }

  login() {
    let validate = true;
    if (this.username == '') {
      validate = false;
    }
    if (this.password == '') {
      validate = false;
    }
    if (!validate) {
      this.error = 'Faltan campos por llenar';
      return;
    }

    this.loading = true;
    const user = {
      Username: this.username,
      Password: this.password,
      AppID: 1
    }
    this.error = '';
    this.authService.login(user).subscribe(data => {
      if (data['resultOperation'] === true) {
        //guardar el token
        localStorage.setItem('username', data['resultObject']['username']);
        localStorage.setItem('token', data['resultObject']['token']);
        this.router.navigate(['/report']);
      } else if (data['resultOperation'] === false) {
        this.error = 'Usuario/contraseña incorrectos';
      }
      this.loading = false;
    }, error => {
      console.log(error);
      this.error = 'Error en el Api, no se pudo acceder al sistema, por favor comuniquese con el administrador';
      this.loading = false;
    });
  }

}
