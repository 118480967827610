import { Injectable } from '@angular/core';
import { Api } from '../shared/api';
import { userlogged } from 'src/app/models/authentication ';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: userlogged = new userlogged();
  helper:JwtHelperService;
  
  constructor(private api: Api) { }


  login(user: any) {
    return this.api.post('api/login/authenticate', user);
  }

  logOut() {
    var promise = new Promise((resolve, reject) => {
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      resolve();
    });
    return promise;
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return this.helper.isTokenExpired(token);
  }

  
  getUserLogged() {
    this.user.userName = localStorage.getItem('username');
    return this.user;
  }

  getToken() {
    return localStorage.getItem('token');
  }

}



