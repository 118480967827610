import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Api } from '../shared/api';
import { AppConfigService } from '../shared/appConfigService';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private api: Api, private http: HttpClient,private appConfigService: AppConfigService) { 
    
  }


  public getReportJSON(): Observable<any> {
    return this.http.get("./assets/Renault.json");
  }

  public getReportCSV(): Observable<any> {
    var url = "./assets/" + this.appConfigService.nameSourceFileExcel;
    return this.http.get(url,{ responseType: 'blob' });
  }

  public getReportApi() {
    return this.api.get("api/REPORT/value");
  }
}
