import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard  {
 
  helper:JwtHelperService;
  
  constructor(private router: Router) {
    this.helper = new JwtHelperService();
   }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var token = localStorage.getItem("token");
    // const decodedToken = this.helper.decodeToken(token)
    // const expirationDate = this.helper.getTokenExpirationDate(token);
    // const isExpired = this.helper.isTokenExpired(token);

    if (token && !this.helper.isTokenExpired(token)){
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
  init() {
  }
}

