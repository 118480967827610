import { Injectable } from '@angular/core';
import { HttpClient,HttpBackend  } from '@angular/common/http';
// export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: any;
  private httpClient: HttpClient;
  constructor(private http: HttpClient, handler: HttpBackend) { 
    this.httpClient = new HttpClient(handler);
  }

  loadAppConfig() {
    return this.httpClient.get('./assets/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });}

  // This is an example property ... you can make it however you want.
  get apiBaseUrl() {
    if (!this.appConfig) {
       throw Error('Config file not loaded!');
    }
    return this.appConfig.apiBaseUrl;
  }

  get timeReloadReport() {
    if (!this.appConfig) {
       throw Error('Config file not loaded!');
    }
    return this.appConfig.timeReloadReportInMilisegundos;
  }

  get nameSourceFileExcel() {
    if (!this.appConfig) {
       throw Error('Config file not loaded!');
    }
    return this.appConfig.nameSourceFileExcel;
  }
}