import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//plugins
import { NgxLoadingModule } from 'ngx-loading';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverModule } from 'ngx-bootstrap/popover';

//Route
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ReportComponent } from './components/report/report.component';

//Pipes
import { FilterReportPipe } from './pipes/filter-report.pipe';

import { LoginComponent } from './components/login/login.component';
import { Api } from './services/shared/api';
import { AuthGuard } from './services/auth/auth.guard';
import { NavBarHeaderComponent } from './components/shared/nav-bar-header/nav-bar-header.component';
import { AuthInterceptor } from './services/auth/authInterceptor.services';
import { AppConfigService } from './services/shared/appConfigService';


@NgModule({
  declarations: [
    AppComponent,
    ReportComponent,
    FilterReportPipe,
    LoginComponent,
    NavBarHeaderComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    CommonModule,
    AccordionModule,
    FormsModule 
  ],
  providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      }
    },

   
    Api,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
