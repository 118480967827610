import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterReport'
})
export class FilterReportPipe implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
        return items;
    }
    return items.filter(item => item.col1.indexOf(filter) !== -1 || item.col2.indexOf(filter) !== -1 || item.col3.indexOf(filter) !== -1 || item.col4.indexOf(filter) !== -1) ;
}
}
