import { Component, OnInit, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { ReportService } from 'src/app/services/report/report.service';
import * as jspdf from 'jspdf';
import { AppConfigService } from 'src/app/services/shared/appConfigService';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  @ViewChild('customLoadingTemplate') customLoadingTemplate: TemplateRef<any>;


  ultimaCargaReporte: string = "";
  timerRecargaReporte: number;

  titleInforme: string = '';
  subTitleInforme: string = '';

  optionPdfShowEncabezado = "everyPage";
  public loading = false;
  public loadingTemplate: TemplateRef<any>;
  oneAtATime: boolean = false;
  openAll = true;

  error: string = "";


  fechaHoy: Date = new Date();
  dataDetailReport: any[] = [];

  refreshIntervalId: any;

  dataHeader = {
    tableSummary: {
      data: [],
      headers: [],
      headersColSpan: []
    }
  }
  dataResult = {
    title: ''
  };

  query = ''; // Variable que se usa para el filtro


  constructor(private reportService: ReportService, private elementRef: ElementRef, private appConfigService: AppConfigService) {
    this.timerRecargaReporte = this.appConfigService.timeReloadReport;
  }


  getReport() {
    this.dataDetailReport = [];
    this.loading = true;
    this.loadingTemplate = null;
    this.reportService.getReportApi().subscribe((i: any) => {
      if (i = "ok") {
        this.reportService.getReportJSON().subscribe(data => {
          for (var item in data['body']) {
            this.dataDetailReport.push(data['body'][item]);
          }
          for (let i of this.dataDetailReport) {
            i['isOpen'] = false;
          }
          this.dataHeader = data['header'];
          this.dataResult = data['footer'];
          console.log(data);
          console.log(this.dataDetailReport);

          this.titleInforme = 'Desglose de Inversiones Financieras | ' + this.dataHeader['fecha'];
          this.subTitleInforme = 'Datos Calculados por Noesis Análisis Financiero S.L.';
          this.loading = false;
          this.ultimaCargaReporte = this.formatDate();
        });
      } else {
        this.loading = false;
      }
      clearInterval(this.refreshIntervalId);
      this.refreshIntervalId = setInterval(() => {
        this.getReport();
      }, this.timerRecargaReporte);
    }, error => {
      console.log(error);
      this.error = 'Error en el Api, no se pudo acceder al sistema, por favor comuniquese con el administrador';
      this.loading = false;
    });
  }

  public exportPdf() {
    this.loading = true;
    this.loadingTemplate = this.customLoadingTemplate;

    var doc = new jspdf('l');
    var fontSize = 7;
    //Encabezado
    doc.text(90, 25, this.titleInforme); //40
    doc.setFontSize(13);
    doc.text(100, 33, this.subTitleInforme) //50

    //Construccion HEADER
    var rowHeaderTableResumen = [];
    var cont = 0;
    for (let i of this.dataHeader['tableSummary']['headers']) {
      var valorColspan;
      if (cont == 0) {
        valorColspan = 1;
        cont = 1;
      } else {
        valorColspan = 2;
      }
      var thTableResumen = { content: i, colSpan: valorColspan, styles: { fontSize: fontSize, halign: 'center' } };
      rowHeaderTableResumen.push(thTableResumen);
    }

    doc.setFontSize(8);
    //Valoracion
    doc.text(130, 57, this.dataHeader['txtValoracion']);
    doc.text(130, 61, this.dataHeader['valoracion']);
    //Duracion RF
    doc.text(160, 57, this.dataHeader['txtDuracionRF']);
    doc.text(160, 61, this.dataHeader['duracionFR']);
    //Convexidad
    doc.text(190, 57, this.dataHeader['txtConvexidad']);
    doc.text(190, 61, this.dataHeader['convexidad']);

    var trTableResumen = [];
    for (let r of this.dataHeader['tableSummary']['data']) {
      var tr = [];
      for (let q of r) {
        var td_ = { content: q, styles: { fontSize: fontSize } };
        tr.push(td_);
      }
      trTableResumen.push(tr);
    }
    doc.autoTable({
      tableWidth: 'wrap',
      // styles: {fillColor: [255, 0, 0]},
      theme: 'grid',
      head: [rowHeaderTableResumen],
      body: trTableResumen,
      startY: 40,
    });

    // Construccion BODY
    var totalPagesExp = "{total_pages_count_string}";
    for (let i of this.dataDetailReport) {
      var header = [];
      var data = [];
      doc.setFontSize(12);
      doc.text(i['title'], 14, doc.previousAutoTable.finalY + 10);
      var rowHeader = [];
      rowHeader.push({ content: "#", styles: { fontSize: fontSize, halign: 'center' } });
      for (let b of i['headers']) {
        b = b.replace("DEFAULT (1 AÑO)", "Dflt (1 Año)");
        b = b.replace("Riesgo Liquidez", "Ries. Liq");
        b = b.replace("Cotización", "Cotiz.");
        b = b.replace("Vencimiento", "Vcto.");
        b = b.replace("AGENCIA", "Agencia");
        b = b.replace("DURA mod", "Dura mod");
        b = b.replace("CONVE", "Conve");
        b = b.replace("Rating", "Rat.");
        b = b.replace("Ponderación", "Pond");
        b = b.replace("GROUP", "Group");
        var th = { content: b, styles: { fontSize: fontSize } };
        rowHeader.push(th);
      }
      header.push(rowHeader);
      var cont = 1;
      var totalRow = i['data'].length;
      for (let c of i['data']) {
        var rowtd = [];
        if (cont == totalRow) {
          rowtd.push({ content: '', styles: { fontSize: fontSize, halign: 'center', fillColor: 'bisque' } });
        } else {
          rowtd.push({ content: cont, styles: { fontSize: fontSize, halign: 'center', fontStyle: 'bold' } });
        }
        for (let d of c) {
          var td;
          if (cont == totalRow) {
            td = { content: d, styles: { fontSize: fontSize, fillColor: 'bisque', fontStyle: 'bold' } };
          } else {
            td = { content: d, styles: { fontSize: fontSize } };
          }
          rowtd.push(td);
        }
        data.push(rowtd);
        cont++;
      }
      doc.autoTable({

        // headStyles:{
        //   cellPadding: { top: 0, right: 0.5, bottom: 0, left:0.5},
        //   cellWidth:'wrap',
        //   overflow: 'linebreak',
        //   minCellHeight: 8,
        //   valign: 'middle'
        // },

        // bodyStyles: { cellPadding: { top: 0, right: 0.5, bottom: 0, left:0.5}, minCellHeight: 8, valign: 'middle', cellWidth:'auto' },
        columnStyles: {
          0: { cellWidth: 'wrap', fontStyle: 'bold' },
          1: { cellWidth: 'wrap' },
          2: { cellWidth: 'auto' },
          3: { cellWidth: 'wrap' },
          4: { cellWidth: 'wrap' },
          5: { cellWidth: 'wrap' },
          6: { cellWidth: 'wrap' },
          7: { cellWidth: 'wrap' },
          8: { cellWidth: 'wrap' },
          9: { cellWidth: 'wrap' },
          10: { cellWidth: 'wrap' },
          11: { cellWidth: 9 },
          12: { cellWidth: 'auto' },
          13: { cellWidth: 'wrap' },
          14: { cellWidth: 'wrap' },
          15: { cellWidth: 'wrap' },
          16: { cellWidth: 11 },
          17: { cellWidth: 'auto' },
        }, // Cells in first column centered and green

        // columnStyles: {
        //   0: { cellWidth: 'wrap', fontStyle: 'bold' },
        //   1: { cellWidth: 'wrap' },
        //   2: { cellWidth: 12 },
        //   3: { cellWidth: 12 },
        //   4: { cellWidth: 10 },
        //   5: { cellWidth: 12 },
        //   6: { cellWidth: 12 },
        //   7: { cellWidth: 'wrap' },
        //   8: { cellWidth: 10 },
        //   9: { cellWidth: 10 },
        //   10: { cellWidth: 12 },
        //   11: { cellWidth: 6 },
        //   12: { cellWidth: 'wrap' },
        //   13: { cellWidth: 10 },
        //   14: { cellWidth: 8 },
        //   15: { cellWidth: 7 },
        //   16: { cellWidth: 8 },
        //   17: { cellWidth: 10 },
        // }, // Cells in first column centered and green
        head: header,
        body: data,


        didDrawPage: function (data) {
          var str = "Pagina " + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str + " de " + totalPagesExp;
          }
          doc.setFontSize(7);
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 8);
        },
        startY: doc.previousAutoTable.finalY === undefined ? 60 : doc.previousAutoTable.finalY + 15,
        showHead: this.optionPdfShowEncabezado,//'everyPage' |'firstPage'|'never'
      });
    }
    // doc.autoTable({
    //     head: [['Name', 'Email', 'Country']],
    //     body: [
    //         ['David', 'david@example.com', 'Sweden'],
    //         ['Castille', 'castille@example.com', 'Norway'],
    //     ]
    // });

    //Construccion FOOTER
    doc.setFontSize(8);
    //Valoracion
    doc.text(60, doc.previousAutoTable.finalY + 20, this.dataResult['title']);
    //Duracion RF
    doc.text(130, doc.previousAutoTable.finalY + 15, this.dataResult['txtPonderacion']);
    doc.text(133, doc.previousAutoTable.finalY + 20, this.dataResult['totalPonderacion']);
    //Convexidad
    doc.text(160, doc.previousAutoTable.finalY + 15, this.dataResult['txtValoracion']);
    doc.text(160, doc.previousAutoTable.finalY + 20, this.dataResult['totalValoracion']);


    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    var fecha = new Date();
    var name = "Informe Renault " + fecha.getFullYear() + "_" + (String(fecha.getMonth() + 1).padStart(2, '0')) + "_" + (String(fecha.getDate()).padStart(2, '0')) + ".pdf";
    doc.save(name);
    this.loading = false;
  }

  generarExcel() {
    this.reportService.getReportCSV().subscribe(data => {
      let extension = "";
      if (data['type'] == 'text/csv') { extension = ".csv" }
      if (data['type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') { extension = ".xlsx" }
      if (data['type'] == 'application/vnd.ms-excel') { extension = ".xls" }
      const url = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = url;
      var fecha = new Date();
      var name = "Informe Renault " + fecha.getFullYear() + "_" + (String(fecha.getMonth() + 1).padStart(2, '0')) + "_" + (String(fecha.getDate()).padStart(2, '0')) + extension;
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    }),
      error => console.log('Error downloading the file.'),
      () => console.info('OK');
  }

  expandAll() {
    this.oneAtATime = !this.oneAtATime;
    this.openAll = !this.openAll;
  }

  formatDate() {
    var d = new Date();
    return d.getDate() + "-" + (String(d.getMonth() + 1).padStart(2, '0')) + "-" + (String(d.getDate()).padStart(2, '0')) + " " +
      (String(d.getHours()).padStart(2, '0')) + ":" + (String(d.getMinutes()).padStart(2, '0')) + ":" + (String(d.getSeconds()).padStart(2, '0'));
  }
  ngOnInit() {
    this.getReport();
  }
}
