import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    helper: JwtHelperService;
    constructor(private authService: AuthService, private router: Router) { 
        this.helper = new JwtHelperService();
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const idToken = localStorage.getItem("token");
        //Si existe el token
        if (idToken) {
            //Valida expiracion del token, si esta expirado se redirijg al login
            if (this.helper.isTokenExpired(idToken)) {
                this.authService.logOut().then(data => {
                    this.router.navigateByUrl('/login');
                    return throwError("Token expirado inicie sesion nuevamente.");
                });
            } else if (!this.helper.isTokenExpired(idToken)) {
                const cloned = req.clone({
                    headers: req.headers.set("Authorization", "Bearer " + idToken).set("Accept", "application/json")
                });
                //Request sin manejo de error:  return next.handle(cloned)
                return next.handle(cloned).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            this.authService.logOut().then(data => {
                                this.router.navigateByUrl('/login');
                            });
                            return throwError("No tiene autorizacion para este recurso.");
                        }
                    }));
            }
        }
        else {
            return next.handle(req);
        }
    }
}